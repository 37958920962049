import { render, staticRenderFns } from "./comp_ProductPage-InfoTabs.vue?vue&type=template&id=d82e13ac&scoped=true&"
import script from "./comp_ProductPage-InfoTabs.vue?vue&type=script&lang=js&"
export * from "./comp_ProductPage-InfoTabs.vue?vue&type=script&lang=js&"
import style0 from "./comp_ProductPage-InfoTabs.vue?vue&type=style&index=0&id=d82e13ac&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d82e13ac",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCard,VTab,VTabItem,VTabs,VTabsItems})
